import React from 'react';
import './App.css';
import TablePage from './pages/table';

import RoutesView, { route } from '@/routes';

function App() {
  return (
    <div className='App'>
      <RoutesView route={route} />
    </div>
  );
}

export default App;
