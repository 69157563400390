import { useState } from 'react';

/** 登录 */
function signin(token: string, callback?: VoidFunction) {
  localStorage.setItem('token', token);
  callback && callback();
}

/** 退出 */
function signout(callback?: VoidFunction) {
  localStorage.removeItem('token');
  callback && callback();
}

/** 用户鉴权 hook */
const useAuth = () => {
  // 实现方式： localStorage (可改用 redux 等)
  const user = localStorage.getItem('token');
  return { user, signin, signout };
};

export default useAuth;
