import { lazy } from 'react';
const route = [
  {
    path: '/login',
    component: lazy(() => import('@/pages/login')),
    meta: {
      name: '登录',
      notShowMenu: true
    }
  },
  {
    path: '/',
    component: lazy(() => import('@/pages/dashBoard')),
    meta: {
      name: '大屏',
      notShowMenu: true
    },
    children:[]
  },
  // {
  //   path: '/',
  //   // component: lazy(() => import('@/layouts/baseicLayout')),
  //   meta: {
  //     name: '首页',
  //     requireAuth: true,
  //     notShowMenu: true
  //   },
  //   // children: [
  //   //   {
  //   //     path: '/',
  //   //     component: lazy(() => import('@/pages/dashBoard')),
  //   //     meta: {
  //   //       name: '首页',
  //   //       index: true
  //   //     }
  //   //   },
  //   //   {
  //   //     path: 'user',
  //   //     component: lazy(() => import('@/pages/user')),
  //   //     meta: {
  //   //       name: '用户'
  //   //     }
  //   //   },
  //   //   {
  //   //     path: 'table',
  //   //     component: lazy(() => import('@/pages/table')),
  //   //     meta: {
  //   //       name: '表格页'
  //   //     }
  //   //   },
  //   //   {
  //   //     path: 'DataAnalysis',
  //   //     component: lazy(() => import('@/pages/DataAnalysis')),
  //   //     meta: {
  //   //       name: '运营数据'
  //   //     }
  //   //   },
  //   //   {
  //   //     path: 'WechatData',
  //   //     component: lazy(() => import('@/pages/WechatData')),
  //   //     meta: {
  //   //       name: '微信数据分析',
  //   //     },
  //   //     children: [
  //   //       {
  //   //         path: '/WechatData/WechatData',
  //   //         component: lazy(() => import('@/pages/WechatData/index2')),
  //   //         meta: {
  //   //           name: '微信数据分析'
  //   //         },
  //   //       },
  //   //       {
  //   //         path: '/WechatData/WechatAerry',
  //   //         component: lazy(() => import('@/pages/WechatAerry')),
  //   //         meta: {
  //   //           name: '123'
  //   //         },
  //   //       },
  //   //     ]
  //   //   },
  //   //   {
  //   //     path: 'MapData',
  //   //     component: lazy(() => import('@/pages/MapData')),
  //   //     meta: {
  //   //       name: '运营统计'
  //   //     }
  //   //   }
  //   // ]
  // },
  // {/* 没有匹配的路径 */}
  {
    path: '*',
    component: lazy(() => import('@/pages/notFound')),
    meta: {
      name: 'NOT FOUND',
      notShowMenu: true
    }
  }
];

export { route };
