import { Routes, Route } from 'react-router-dom';
import RequireAuthWraper from '@/components/auth';
import React from 'react';

const RoutesView = ({ route }: { route: any }) => {
  const getRoute = (route: any) => {
    return route.map((item: any, index: number) => {
      const { path, component: Component, meta } = item;
      let element = (
        <React.Suspense fallback={<>...</>}>
          <Component />
        </React.Suspense>
      );
      if (item.meta.requireAuth) {
        element = <RequireAuthWraper>{element}</RequireAuthWraper>;
      }
      return item.children ? (
        <React.Fragment key={index}>
          <Route index={meta.index ?? undefined} path={meta.index ? '/' : path} element={element}>
            {getRoute(item.children)}
          </Route>
        </React.Fragment>
      ) : (
        <React.Fragment key={index}>
          <Route index={meta.index ?? undefined} path={meta.index ? '/' : path} element={element} />
        </React.Fragment>
      );
    });
  };
  return <Routes>{getRoute(route)}</Routes>;
};

export default RoutesView;
