/** 用户鉴权 */

import { Navigate, useLocation } from 'react-router-dom';
import useAuth from './useAuth';

function RequireAuthWraper({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  const location = useLocation();
  // 是否登录判断
  // if (!auth.user) {
  //   alert('请先登录');
  //   // state from 参数 可在登录页获取到 登录成功后直接跳转
  //   return <Navigate to='/login' state={{ from: location }} replace />;
  // }

  return children;
}
export default RequireAuthWraper;
